import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/eWPT.png";
import editor from "../../Assets/Projects/AI.png";
import chatify from "../../Assets/Projects/htb.png";
import suicide from "../../Assets/Projects/ejpt.png";
import bitsOfCode from "../../Assets/Projects/introduction-hacking.png";
import HackingNotes from "../../Assets/Projects/hacking.png";
import { SocialLinks } from "./Toolstack";
import { Toolstack } from "./Toolstack";


function Projects() {
  return (
<Container fluid className="project-section" id="project">      
  <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Travaux Récents</strong>
        </h1>
        <p style={{ color: "white" }}>
          Voici quelques projets sur lesquels j'ai récemment travaillé.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title={<strong>Plateforme Entrainement</strong>}
              description={
                <div style={{ color: "white" }}>
                  <strong className="purple">HackTheBox </strong> est une
                  plateforme sur laquelle je m'entraîne régulièrement, en
                  affrontant des environnements réels qui présentent diverses
                  situations et défis, tels que l'exploitation de vulnérabilités,
                  la résolution d'énigmes de sécurité et l'élévation de privilèges.
                </div>
              }
              cubeLink="https://hackthebox.com/"
              alwaysShowDescription={true}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={HackingNotes}
              isBlog={false}
              title={<strong>Hacking Notes</strong>}
              description={
                <div style={{ color: "white" }}>
                  Voici mon 
                  <strong className="purple"> site web </strong> dédié à la <strong className="purple">cybersécurité offensive</strong>, 
                  où je partage mes connaissances et recherches. Vous y trouverez des ressources, des méthodologies et des procédures 
                  mises à jour régulièrement.


                  
                </div>
              }
              notesLink="https://hacking-notes.jord4n.pro/"
              alwaysShowDescription={true}
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title={<strong>Certification eJPT (en cours)</strong>}
              description={
                <div style={{ color: "white" }}>
                  Actuellement en préparation pour la certification
                  <strong className="purple"> eJPT</strong>, dédiée aux personnes débutant dans 
                  le pentesting, un examen pratique de 
                  <strong className="purple"> 48 heures</strong>.
                   Cette certification valide des compétences avancées en identification et exploitation de vulnérabilités.
                </div>
              }
              certificationLink="https://security.ine.com/certifications/ejpt-certification/"
              alwaysShowDescription={true}
            />
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

                      <Col md={4} className="project-card"> 
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title={<strong>Projet d'intelligence artificielle</strong>}
              description={
                <div style={{ color: "white" }}>
                  Développement d'une solution <strong className="purple">IA Asterisk</strong> pour automatiser la gestion des alarmes et des appels  :
                <br></br>
                L'objectif en utilisant les API Cloud Azure et Google est d'intégrer ces technologies à un serveur {" "}
                <strong className="purple">Asterisk</strong>, spécialisé dans la téléphonie, afin de mettre en place des fonctionnalités de{" "}
                <strong className="purple">reconnaissance vocale (STT)</strong>, de{" "}
                <strong className="purple">synthèse vocale (TTS)</strong> et un serveur d’IA capable d'analyser les situations
                et de prendre des{" "}
                <strong className="purple">décisions</strong> de manière autonome.
                <p>La finalité est de réduire et filtrer 90 % des appels dus à des erreurs de manipulation des clients.</p>
              </div>
              
              
              }
              alwaysShowDescription={false}
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title={<strong>Formation d'Initiation au Hacking</strong>}
              description={
                <div style={{ color: "white" }}>
                  J'ai investi environ <strong className="purple">150 heures </strong>dans ce cours, explorant divers domaines et beaucoup de pratique:


                  <br />
                  <br />
                  - <strong className="purple">Reconnaissance </strong> :
                  Exploration du réseau, énumération web, identification des
                  technologies web, etc.
                  <br />
                  - <strong className="purple">Failles de services et gestionnaires de contenu </strong> : FTP, SSH, SMB, WordPress, Joomla, Drupal, etc.
                  <br />
                  - <strong className="purple">OWASP TOP 10 et vulnérabilités web </strong> : SQLI, XSS, XXE, LFI, NoSQL, abus d'API, etc.
                  <br />
                  - <strong className="purple">Élévation de privilèges </strong> : Sudoers, SUID, PATH Hijacking, Kernel, et les groupes spéciaux, etc.
                  <br />
                  - <strong className="purple">Buffer overflow </strong> : J'ai acquis une compréhension approfondie de la technique du buffer overflow.
                  <br />
                  - <strong className="purple">Résolution de 5 machines vulhub </strong> : J'ai mis en pratique mes connaissances avec cinq machines vulhub.
                  <br />
                  - <strong className="purple">Outils additionnels </strong> : J'ai été introduit à des outils supplémentaires tels que Metasploit, SQLmap et les techniques de pivoting.
                  <br />
                  - <strong className="purple">Rédaction de rapports </strong> : Acquisition de compétences dans la rédaction de rapports professionnels en utilisant LaTeX.
                </div>
              }              
              courseLink="https://hack4u.io/cursos/introduccion-al-hacking/"
              alwaysShowDescription={false}

            />
          </Col>
          </Row>
        <SocialLinks />
      </Container>
    </Container>

  );
}

export default Projects;
