import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaUser, FaGraduationCap, FaBriefcase, FaCertificate } from "react-icons/fa";
import "./HomeAndAbout.css";

function HomeAndAbout() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        {/* Section 1: Présentation */}
        <Row className="mb-5">
          <Col md={12} className="home-about-description text-center">
            <h1 className="section-title">
              <span className="purple"><b>$</b></span> whoami
            </h1>
            <p className="home-about-body">
              <b><span className="purple">Jordan Macia De Las Heras</span></b>
              <br />
              Futur étudiant en Mastère Expert Cybersécurité à Ynov Campus Toulouse, je suis passionné par la cybersécurité offensive et le pentesting.
              <br />
              Âgé de 20 ans, je suis né en Andorre 🇦🇩, où j’ai vécu jusqu’à il y a trois ans.
              <br />
              Langues : <span className="purple">Espagnol</span>, <span className="purple">Catalan</span>, <span className="purple">Français</span>, et bon niveau en <span className="purple">Anglais</span>.
              <br />
            </p>
          </Col>
        </Row>

        {/* Section 2: Détails sur le parcours */}
        <Row className="mb-5">
          {/* Études */}
          <Col md={4} sm={12} className="mb-4">
            <Card className="h-100 card-hover transparent-card">
              <Card.Body>
                <h2 className="purple"><FaGraduationCap className="icon" /> <strong>Études</strong></h2>
                <p>
                  <b className="purple">Mastère Expert Cybersécurité</b>

                  <br />
                  <em>Ynov Campus (2025-2027)</em>
                  <ul className="text-left">

                    <li>....</li>
                  </ul>
                  <b></b>
                  <b className="purple">Bachelor Cybersécurité (Alternance)</b>

                  <br />
                  <em>Ynov Campus (2024-2025)</em>
                  <br />                  

                  <ul className="text-left">
                  <br />                  

                  <li>Pentesting</li>
                    <li>Sécurité des systèmes d'exploitation et des services</li>
                    <li>Administration de BDD</li>
                    <li>Gouvernance de la sécurité</li>
                  </ul>
                  <b></b>
                  <b className="purple">BTS SIO option SISR</b><br />
                 <em>Lycée Ozenne (2022-2024)</em>
                  <br />                  
                  <br />
                  <ul className="text-left">

                  <li>Administration système et réseau</li>
                  <li>Sécurisation des SI</li>
                  <li>Développement (Python, PHP, JavaScript)</li>
                  </ul>
                </p>
              </Card.Body>
            </Card>
          </Col>

          {/* Expérience professionnelle */}
          <Col md={4} sm={12} className="mb-4">
            <Card className="h-100 card-hover transparent-card">
              <Card.Body>
              <h2 className="purple">
                <FaBriefcase className="icon" /> <strong>Expériences pro</strong>
              </h2>
                <p>
                <b className="purple">Alternance chez Doomap </b>                  <br />
               <em>(Janv. 2025 - Aujourd'hui)</em>
              
                  <br></br>
                                
                <ul className="text-left">
                  <br></br>
                    <li>Audit de cybersécurité et renforcement interne.</li>
                    <li>Déploiement IA sur Asterisk (API Speech-to-Text, Text-to-Speech).</li>
                    <li>Installation, gestion et maintenance des infrastructures informatiques       .</li>
                    <li>Développement Python, PHP, JavaScript, SQL.</li>
                </ul>


                  <b></b>
                  <b className="purple">Stage Andorra Telecom </b>                  <br />
                  <em>(5 semaines)</em>

                  <ul className="text-left">
                  <br></br>

                  <li>Gestion des alertes et journaux (SIEM ELK, EDR Defender, Netskope).</li>
                  <li>Support NOC/SOC (traitement des alarmes et alertes).</li>
                  </ul>
                </p>
              </Card.Body>
            </Card>
          </Col>

          {/* Certifications */}
          <Col md={4} sm={12} className="mb-4">
            <Card className="h-100 card-hover transparent-card">
              <Card.Body>
              <h2 className="purple">
                <FaCertificate className="icon" /> <strong>Certifications</strong>
              </h2>
                <p className="text-left">
                Grâce à un entraînement sur plus de 80 machines (Linux/Windows) via HackTheBox, VulHub et PortSwigger, j'ai développé des compétences avancées en sécurité offensive, avec les certifications suivantes :

<br /><br />
                  <ul>
                  <li><span className="purple">eJPT</span>  (INE) - En cours...</li>
                  <li><span className="purple">Introduction au Hacking</span> (Hack4u.io) - juin 2023</li>
                  </ul>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default HomeAndAbout;